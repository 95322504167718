import React, { FC } from "react";
import "./SmartTools.css";

interface SmartToolsProps {}

const SmartTools: FC<SmartToolsProps> = () => (
  <div className="SmartTools">Coming Soon</div>
);

export default SmartTools;
