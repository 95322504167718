import React, { FC, useState } from "react";
import "./Categories.css";
import FeaturedSlider from "../FeaturedSlider/FeaturedSlider";
import MainSlider from "../MainSlider/MainSlider";
import TopSlider from "../TopSlider/TopSlider";
import CategoriesList from "../CategoriesList/CategoriesList";
import { useSearchParams } from "react-router-dom";
import TechCategory from "../TechCategory/TechCategory";
import TechCategoryList from "../TechCategoryList/TechCategoryList";

interface CategoriesProps {}

const Categories: FC<CategoriesProps> = () => {
  const [cat, setCat] = useState(true);
  const [catId, setCatId] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const aid = searchParams.get("catid");
  const y = Number(aid);

  const qqry = searchParams.get("qqry");
  const qq = Boolean(qqry);

  const aqry = searchParams.get("aqry");
  const aq = Boolean(aqry);

  return (
    <div className="Categories">
      {aid && <TechCategory catId={y} qas={qq} ars={aq} />}
      {!aid && <TechCategoryList />}
    </div>
  );
};
export default Categories;
