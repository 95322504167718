import React, { FC } from "react";
import "./NewsLetter.css";

interface NewsLetterProps {}

const NewsLetter: FC<NewsLetterProps> = () => {
  return (
    <>
      <div className="pb-3">
        <div className="bg-light py-2 px-4 mb-3">
          <h3 className="m-0">Newsletter</h3>
        </div>
        <div className="bg-light text-center p-4 mb-3">
          <p>Subscribe to our weekly newsletter</p>

          <div className="input-group hundredper">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Your Email"
            />
            <div className="input-group-append">
              <button className="btn btn-primary">Sign Up</button>
            </div>
          </div>
          {/* <small>Sit eirmod nonumy kasd eirmod</small> */}
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
