import React, { FC, useState } from "react";
import "./AddArticle.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Navbar from "../Navbar/Navbar";
import Topbar from "../Topbar/Topbar";
import { Helmet } from "react-helmet";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

interface AddArticleProps {}

const AddArticle: FC<AddArticleProps> = () => {
  const [value, setValue] = useState("");

  const module = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  return (
    <>
      <Helmet>
        <title>Nuhman.com - Last Rank Details of Kerala Engineering 2023</title>
      </Helmet>
      <BreadCrumb
        links={[
          { link: "test", title: "test" },
          { link: "test", title: "test" },
        ]}
        title={"Add Article"}
      />

      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult">
                  <h3 className="mb-4">Add Article</h3>

                  <ReactQuill
                    theme="snow"
                    value={value}
                    modules={module}
                    onChange={setValue}
                  />
                </div>
                <div>{value}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddArticle;
