import React, { FC, useState } from "react";
import "./TechCategoryList.css";
import RightSideList from "../RightSideList/RightSideList";
import { techCategory } from "../../interfaces";
import axios from "axios";

interface TechCategoryListProps {}

const TechCategoryList: FC<TechCategoryListProps> = () => {
  const url = `https://apis.nuhman.com/`;
  const [categories, setCategories] = useState<techCategory[]>();

  axios
    .get(url + `apis/nuhman/tech/categories`)
    .then((res) => {
      setCategories(res?.data);
    })
    .catch((err) => console.log(err));

  return (
    <div className="container-fluid py-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {categories?.map((ctry: techCategory, index) => (
              <>
                <div className="col-sm-6 intdisp" key={index}>
                  <p>
                    <a
                      key={index}
                      href={
                        "/#/categories?catid=" +
                        ctry.id +
                        "&qqry=true&aqry=true"
                      }
                    >
                      {ctry.name}
                    </a>
                  </p>
                </div>
              </>
            ))}
          </div>

          <RightSideList />
        </div>
      </div>
    </div>
  );
};

export default TechCategoryList;
