import React, { FC } from "react";
import "./TagList.css";
import TagFetcher from "../TagFetcher/TagFetcher";

interface TagListProps {}

const TagList: FC<TagListProps> = () => {
  return (
    <div className="pb-3">
      <div className="bg-light py-2 px-4 mb-3">
        <h3 className="m-0">Tags</h3>
      </div>
      <div className="d-flex flex-wrap m-n1">
        <TagFetcher />
      </div>
    </div>
  );
};

export default TagList;
