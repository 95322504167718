import React, { FC, useEffect, useState } from "react";
import "./Article.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Article as art, TechArticle, TechArticles } from "../../interfaces";
import { useSearchParams } from "react-router-dom";
import parse from "html-react-parser";
import Trending from "../Trending/Trending";

interface ArticleProps {}

const Article: FC<ArticleProps> = () => {
  const [message, setMessage] = useState<string>();
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [responses, setResponses] = useState<string>();
  const [showForm, setShowForm] = useState(true);
  const [articler, setArticler] = useState<TechArticle>();
  const url = `https://apis.nuhman.com/`;
  const [trendingContents, setTrendingContents] = useState<TechArticles>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const aid = searchParams.get("articleid");
  function submitContact(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    //throw new Error("Function not implemented.");
    setShowForm(false);
    axios
      .post(url + `apis/nuhman/tech/comment`, {
        articleId: aid,
        name: name,
        email: email,
        subject: subject,
        comment: message,
      })
      .then((res) => {
        setResponses(
          res?.status == 200
            ? "Comment submitted successfully...Will be posted after review."
            : "Error in Form Submission try again after some time..."
        );
      })
      .catch((err) => console.log(err));

    event.currentTarget.reset();
  }

  useEffect(() => {
    setShowForm(true);
    axios
      .get(url + `apis/nuhman/tech/articles?views=1&page=1&size=4`)
      .then((res) => {
        //setCountries(res?.data);
        setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + "apis/nuhman/tech/articles/" + aid)
      .then((res) => {
        setArticler(res?.data);
      })
      .catch((err) => console.log(err));
  }, [aid]);

  function geTMap(arg0: string | undefined) {
    return arg0?.split(",").map((item) => item.trim());
  }

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - " + articler?.title}</title>
        <meta name="description" content={articler?.description} />
        <meta property="og:title" content={"Nuhman.com - " + articler?.title} />
        <meta property="og:description" content={articler?.description} />
      </Helmet>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult col-lg-12">
                  <a
                    href={
                      "/#/categories?aqry=true&qqry=true&catid=" +
                      (articler && articler?.techCategory?.id)
                    }
                  >
                    {articler && articler?.techCategory?.name}
                  </a>
                  <span className="px-1">/</span>
                  <span>
                    {articler &&
                      new Date(
                        articler?.createdDateTime || ""
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                  </span>
                  <h3 className=" mg">{articler?.title}</h3>
                  <p>
                    {geTMap(
                      ((articler && articler?.tags) || "") +
                        (articler?.generatedTags || "")
                    )?.map((ctry: string, index) => (
                      <a href={"/#/tags?name=" + ctry} key={index}>
                        <span className="tsps">{ctry}</span>
                      </a>
                    ))}
                  </p>
                  <div className="twid">
                    {parse(articler?.description || "")}
                  </div>
                  <div>
                    <h5>Comments</h5>
                    <p>No comments yet</p>
                    <h6>Add a new Comment</h6>
                    {showForm && (
                      <form
                        name="sentMessage"
                        id="contactForm"
                        onSubmit={submitContact}
                      >
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="control-group">
                              <input
                                type="text"
                                className="form-control p-4"
                                id="name"
                                name="name"
                                onChange={(event) =>
                                  setName(event.target.value)
                                }
                                placeholder="Your Name"
                                required
                                data-validation-required-message="Please enter your name"
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="control-group">
                              <input
                                type="email"
                                className="form-control p-4"
                                id="email"
                                name="email"
                                onChange={(event) =>
                                  setEmail(event.target.value)
                                }
                                placeholder="Your Email"
                                required
                                data-validation-required-message="Please enter your email"
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                        </div>
                        <div className="control-group">
                          <input
                            type="text"
                            className="form-control p-4"
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                            onChange={(event) => setSubject(event.target.value)}
                            required
                            data-validation-required-message="Please enter a subject"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                          <textarea
                            className="form-control"
                            rows={4}
                            id="message"
                            name="message"
                            placeholder="Comment"
                            onChange={(event) => setMessage(event.target.value)}
                            required
                            data-validation-required-message="Please enter your message"
                          ></textarea>
                          <p className="help-block text-danger"></p>
                        </div>
                        <div>
                          <button
                            className="btn btn-primary font-weight-semi-bold px-4"
                            style={{ height: 50 }}
                            type="submit"
                            id="sendMessageButton"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                    {!showForm && (
                      <>
                        <div className="alert alert-success" role="alert">
                          <h4 className="alert-heading">
                            Comment Submitted Successfully...
                          </h4>
                          <p>We will verify and publish...</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <Trending articles={trendingContents} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Article;
