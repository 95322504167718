import React, { FC, useEffect, useState } from "react";
import "./TechCategory.css";
import axios from "axios";
import {
  ArticleContentType,
  TechArticle,
  TechArticles,
  techCategory,
} from "../../interfaces";
import FollowUs from "../FollowUs/FollowUs";
import NewsLetter from "../NewsLetter/NewsLetter";
import { useSearchParams } from "react-router-dom";
import SideAd from "../SideAd/SideAd";
import Trending from "../Trending/Trending";
import TagList from "../TagList/TagList";
import RightSideList from "../RightSideList/RightSideList";

interface TechCategoryProps {
  catId: number;
  qas: boolean;
  ars: boolean;
}

const TechCategory: FC<TechCategoryProps> = ({ catId, qas, ars }) => {
  const [popularContents, setPopularContents] = useState<TechArticles>();
  const url = `https://apis.nuhman.com/`;
  const [articleCategory, setArticleCategory] = useState<techCategory>();
  const [qa, setQa] = useState<boolean>(false);
  const [ar, setAr] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/category/` + catId)
      .then((res) => {
        setArticleCategory(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/nuhman/tech/articles?categoryId=` + catId)
      .then((res) => {
        //setCountries(res?.data);
        setPopularContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function geTMap(arg0: string | undefined) {
    return arg0?.split(",").map((item) => item.trim());
  }

  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {ars && (
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      <h3 className="m-0">{articleCategory?.name} Articles </h3>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {popularContents?.techArticles?.map(
                      (article: TechArticle, index) => (
                        <>
                          <div className="col-lg-6 d-flex mb-3 drr" key={index}>
                            <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                              <div className="mb-1 headerthirteen">
                                <a
                                  href={
                                    "/#/categories?aqry=true&qqry=true&catid=" +
                                    (article && article?.techCategory?.id)
                                  }
                                >
                                  {article && article?.techCategory?.name}
                                </a>
                                <span className="px-1">/</span>
                                <span>
                                  {article &&
                                    new Date(
                                      article?.createdDateTime || ""
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })}
                                </span>
                              </div>
                              <a
                                className="h6 m-0"
                                href={
                                  "/#/article?articleid=" +
                                  (article && article?.id)
                                }
                              >
                                {article && article?.title}
                              </a>
                              <p className="tspl">
                                {geTMap(article && article?.tags)
                                  ?.slice(0, 5)
                                  ?.map((ctry: string, index) => (
                                    <a
                                      href={"/#/tags?name=" + ctry}
                                      key={index}
                                    >
                                      <span className="tsps">{ctry}</span>
                                    </a>
                                  ))}
                              </p>
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
              )}

              {qas && (
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      <h3 className="m-0">Q & A </h3>
                    </div>
                    <p>No QA</p>
                  </div>
                </div>
              )}
            </div>

            <RightSideList />
          </div>
        </div>
      </div>
    </>
  );
};

export default TechCategory;
