import React, { FC, useEffect, useState } from "react";
import "./JwtTokenGenerator.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import sign from "jwt-encode";

interface JwtTokenGeneratorProps {}

interface Claim {
  name: string;
  value: string;
}

const JwtTokenGenerator: FC<JwtTokenGeneratorProps> = () => {
  const [description, setDescription] = useState<string>();
  const [result, setResult] = useState<string>();
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [issuer, setIssuer] = useState<string>();
  const [audience, setAudience] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [secret, setSecret] = useState<string>("");
  const [issueDate, setIssueDate] = useState<string>();
  const [expireDate, setExpireDate] = useState<string>();
  const [customClaims, setCustomClaims] = useState<Claim[]>([]);
  const [claims, setClaims] = useState([]);
  const [name, setName] = useState<string>();
  const [value, setValue] = useState<string>();
  const [errorClaims, setErrorClaims] = useState<string>(
    "No customs claims added"
  );
  const [textColor, setTextColor] = useState("black");
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [toDateTime, setToDateTime] = useState("");
  const [iat, setIat] = useState<number | null>(null);
  const [eat, setEat] = useState<number | null>(null);
  const [jwt, setJwt] = useState<string | null>(null);
  const [dataString, setDataString] = useState<string | null>(null);

  const url = `https://apis.nuhman.com/`;
  function submitContact(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setLoading(true);
    let iatValue = 0;
    let eatValue = 0;
    if (currentDateTime && toDateTime) {
      iatValue = convertToUnixTimestamp(currentDateTime);
      eatValue = convertToUnixTimestamp(toDateTime);

      setIat(iatValue);
      setEat(eatValue);
    }
    const data = {
      iss: issuer,
      sub: sign,
      aud: audience,
      iat: iatValue,
      exp: eatValue,
      ...customClaims,
    };

    const jwt = sign(data, secret);
    const jwtStringify = JSON.stringify(data, null, 2);
    setJwt(jwt);
    setDataString(jwtStringify);
    setLoading(false);
  }

  function showAddCustomClaim(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.preventDefault();
    if (!name || !value) {
      setErrorClaims("Name and Value should present");
      setTextColor("red");
    } else {
      const newObject = [
        ...customClaims,
        {
          name,
          value,
        },
      ];
      setCustomClaims(newObject);
      setErrorClaims("");
      setTextColor("black");
    }
  }
  const getCurrentDateTime = (now: Date, n: number) => {
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours() + n).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  function dateToStr(d: Date) {
    return d.toISOString().slice(0, 16);
  }

  function convertDateToJWTIat(date: Date): number {
    // Get the UTC timestamp in seconds (not milliseconds)
    return Math.floor(date.getTime() / 1000);
  }

  // Helper function to convert date string (yyyy-mm-ddThh:mm) to Unix timestamp
  const convertToUnixTimestamp = (dateTimeString: string): number => {
    return Math.floor(new Date(dateTimeString).getTime() / 1000); // convert milliseconds to seconds
  };

  useEffect(() => {
    const dates = new Date();
    setCurrentDateTime(getCurrentDateTime(dates, 0));
    setToDateTime(getCurrentDateTime(dates, 1));
  }, []);

  useEffect(() => {
    const url = `https://apis.nuhman.com/`;
    axios
      .post(url + `apis/nuhman/tech/page-view?pageId=` + JwtTokenGenerator.name)
      .then((res) => {
        //setCountries(res?.data);
        //setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - Jwt Token Generator"}</title>
        <meta
          name="description"
          content={
            "Jwt Token Generator - Add Issuer, Subject, Audience, Secret and Custom Claims and generate jwt token"
          }
        />
        <meta
          property="og:title"
          content={"Nuhman.com - Jwt Token Generator"}
        />
        <meta
          property="og:description"
          content={
            "Jwt Token Generator - Add Issuer, Subject, Audience, Secret and Custom Claims and generate jwt token"
          }
        />
      </Helmet>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult col-lg-12">
                  <h3 className=" mg">{"Jwt Token Generator"}</h3>
                  <p>
                    Please add an Issuer, Subject, Audience, Secret and if any
                    additional custom claims
                  </p>

                  {loading && (
                    <>
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                  )}

                  {!loading && (
                    <>
                      {" "}
                      <form
                        name="sentMessage"
                        id="contactForm"
                        onSubmit={submitContact}
                      >
                        <div className="control-group">
                          <input
                            type="text"
                            className="form-control p-4"
                            id="issuer"
                            name="issuer"
                            placeholder="Issuer"
                            onChange={(event) => setIssuer(event.target.value)}
                            required
                            data-validation-required-message="Please enter issuer name"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                          <input
                            type="text"
                            className="form-control p-4"
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                            onChange={(event) => setSubject(event.target.value)}
                            required
                            data-validation-required-message="Please enter subject"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                          <input
                            type="text"
                            className="form-control p-4"
                            id="audience"
                            name="audience"
                            placeholder="Audience"
                            onChange={(event) =>
                              setAudience(event.target.value)
                            }
                            required
                            data-validation-required-message="Please enter subject"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                          <input
                            type="text"
                            className="form-control p-4"
                            id="secret"
                            name="secret"
                            placeholder="Secret"
                            onChange={(event) => setSecret(event.target.value)}
                            required
                            data-validation-required-message="Please enter subject"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-row">
                          &nbsp;&nbsp;<h5>Custom Claims</h5>
                        </div>
                        {/* {customClaims &&
                          customClaims?.length > 0 &&
                          customClaims.map((claim: Claim, index) => (
                            <>
                              &nbsp;
                              <div className="form-row">
                                &nbsp;&nbsp;{claim.name} - {claim.value}{" "}
                              </div>
                            </>
                          ))}
                        <div className="form-row">
                          &nbsp;&nbsp;
                          <p style={{ color: textColor }}>{errorClaims}</p>
                        </div>
                        <div className="form-row">
                          <div className="col-md-4">
                            <div className="control-group">
                              <input
                                type="text"
                                className="form-control p-4"
                                id="names"
                                name="names"
                                onChange={(event) =>
                                  setName(event.target.value)
                                }
                                placeholder="Name"
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="control-group">
                              <input
                                type="text"
                                className="form-control p-4"
                                id="value"
                                name="value"
                                onChange={(event) =>
                                  setValue(event.target.value)
                                }
                                placeholder="Value"
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="control-group">
                              <button
                                type="button"
                                className="btn btn-primary font-weight-semi-bold px-4"
                                style={{ height: 50 }}
                                onClick={showAddCustomClaim}
                              >
                                Add Custom Claim(s)
                              </button>
                            </div>
                          </div>
                        </div> */}

                        <div className="form-row">
                          <div className="col-md-6">
                            <p>Issued data/time (UTC)</p>
                            <div className="control-group">
                              <input
                                type="datetime-local"
                                id="datetime"
                                value={currentDateTime}
                                onChange={(e) =>
                                  setCurrentDateTime(e.target.value)
                                }
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <p>Expiration date/time (UTC)</p>
                            <div className="control-group">
                              <input
                                type="datetime-local"
                                id="datetime"
                                value={toDateTime}
                                onChange={(e) => setToDateTime(e.target.value)}
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            className="btn btn-primary font-weight-semi-bold px-4"
                            style={{ height: 50 }}
                            type="submit"
                            id="sendMessageButton"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </>
                  )}

                  <div className="col-md-12 tw">
                    {jwt && (
                      <>
                        {" "}
                        <br />
                        <p>{jwt}</p>
                      </>
                    )}
                  </div>
                  <div>
                    {dataString && (
                      <>
                        {" "}
                        <br />
                        <p>{dataString}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <p>**</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JwtTokenGenerator;
