import React, { FC } from "react";
import "./SideAd.css";

interface SideAdProps {}

const SideAd: FC<SideAdProps> = () => {
  return (
    <div className="mb-3 pb-3">
      <script
        type="text/javascript"
        src="https://udbaa.com/bnr.php?section=General&pub=572899&format=300x250&ga=g"
      ></script>
      <noscript>
        <a href="https://yllix.com/publishers/572899" target="_blank">
          <img
            src="//ylx-aff.advertica-cdn.com/pub/300x250.png"
            className="ylixs"
            alt="ylliX - Online Advertising Network"
          />
        </a>
      </noscript>
    </div>
  );
};

export default SideAd;
