import React, { FC, useEffect, useState } from "react";
import "./Sidebar.css";
import FollowUs from "../FollowUs/FollowUs";
import NewsLetter from "../NewsLetter/NewsLetter";
import { ArticleContentType, TechArticles } from "../../interfaces";
import axios from "axios";
import parse from "html-react-parser";

interface SidebarProps {
  isLoading: boolean;
}

const Sidebar: FC<SidebarProps> = ({ isLoading }) => {
  const [articleContentTypes, setArticleContentTypes] =
    useState<ArticleContentType[]>();
  const [popularContents, setPopularContents] = useState<TechArticles>();
  const [trendingContents, setTrendingContents] =
    useState<ArticleContentType>();
  const [latestContents, setLatestContents] = useState<TechArticles>();
  const url = `https://apis.nuhman.com/`;

  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/articles?views=1`)
      .then((res) => {
        //setCountries(res?.data);
        setPopularContents(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/nuhman/tech/articles?created=1`)
      .then((res) => {
        //setCountries(res?.data);
        setLatestContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, [isLoading]);

  function geTMap(arg0: string | undefined) {
    return arg0?.split(",").map((item) => item.trim());
  }

  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h3 className="m-0">Popular</h3>
                    <a
                      className="text-secondary font-weight-medium text-decoration-none"
                      href=""
                    >
                      View All
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (popularContents?.techArticles &&
                              popularContents?.techArticles[3]?.techCategory
                                ?.id)
                          }
                        >
                          {popularContents?.techArticles &&
                            popularContents?.techArticles[3]?.techCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.techArticles &&
                            new Date(
                              popularContents?.techArticles[3]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.techArticles &&
                            popularContents?.techArticles[3].id)
                        }
                      >
                        {popularContents?.techArticles &&
                          popularContents?.techArticles[3].title}
                      </a>
                      <p className="tspl">
                        {geTMap(
                          popularContents?.techArticles &&
                            popularContents?.techArticles[3].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (popularContents?.techArticles &&
                              popularContents?.techArticles[1]?.techCategory
                                ?.id)
                          }
                        >
                          {popularContents?.techArticles &&
                            popularContents?.techArticles[1]?.techCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.techArticles &&
                            new Date(
                              popularContents?.techArticles[1]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.techArticles &&
                            popularContents?.techArticles[1]?.id)
                        }
                      >
                        {popularContents?.techArticles &&
                          popularContents?.techArticles[1]?.title}
                      </a>
                      <p className="tspl">
                        {geTMap(
                          popularContents?.techArticles &&
                            popularContents?.techArticles[1].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (popularContents?.techArticles &&
                              popularContents?.techArticles[2]?.techCategory
                                ?.id)
                          }
                        >
                          {popularContents?.techArticles &&
                            popularContents?.techArticles[2]?.techCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.techArticles &&
                            new Date(
                              popularContents?.techArticles[2]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.techArticles &&
                            popularContents?.techArticles[2].id)
                        }
                      >
                        {popularContents?.techArticles &&
                          popularContents?.techArticles[2].title}
                      </a>
                      <p className="tspl">
                        {geTMap(
                          popularContents?.techArticles &&
                            popularContents?.techArticles[2].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (popularContents?.techArticles &&
                              popularContents?.techArticles[0]?.techCategory
                                ?.id)
                          }
                        >
                          {popularContents?.techArticles &&
                            popularContents?.techArticles[0]?.techCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.techArticles &&
                            new Date(
                              popularContents?.techArticles[0]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.techArticles &&
                            popularContents?.techArticles[0]?.id)
                        }
                      >
                        {popularContents?.techArticles &&
                          popularContents?.techArticles[0]?.title}
                      </a>
                      <p className="tspl">
                        {geTMap(
                          popularContents?.techArticles &&
                            popularContents?.techArticles[0].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h3 className="m-0">Latest</h3>
                    <a
                      className="text-secondary font-weight-medium text-decoration-none"
                      href=""
                    >
                      View All
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (latestContents?.techArticles &&
                              latestContents?.techArticles[0]?.techCategory?.id)
                          }
                        >
                          {latestContents?.techArticles &&
                            latestContents?.techArticles[0].techCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.techArticles &&
                            new Date(
                              latestContents?.techArticles[0]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.techArticles &&
                            latestContents?.techArticles[0].id)
                        }
                      >
                        {latestContents?.techArticles &&
                          latestContents?.techArticles[0].title}
                      </a>
                      <p className="tspl">
                        {geTMap(
                          latestContents?.techArticles &&
                            latestContents?.techArticles[0].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (latestContents?.techArticles &&
                              latestContents?.techArticles[1].techCategory?.id)
                          }
                        >
                          {latestContents?.techArticles &&
                            latestContents?.techArticles[1].techCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.techArticles &&
                            new Date(
                              latestContents?.techArticles[1]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.techArticles &&
                            latestContents?.techArticles[1]?.id)
                        }
                      >
                        {latestContents?.techArticles &&
                          latestContents?.techArticles[1]?.title?.substring(
                            0,
                            66
                          ) + "...."}
                      </a>
                      <p className="tspl">
                        {geTMap(
                          latestContents?.techArticles &&
                            latestContents?.techArticles[1].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (latestContents?.techArticles &&
                              latestContents?.techArticles[2]?.techCategory?.id)
                          }
                        >
                          {latestContents?.techArticles &&
                            latestContents?.techArticles[2]?.techCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.techArticles &&
                            new Date(
                              latestContents?.techArticles[2]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.techArticles &&
                            latestContents?.techArticles[2]?.id)
                        }
                      >
                        {latestContents?.techArticles &&
                          latestContents?.techArticles[2]?.title}
                      </a>
                      <p className="tspl">
                        {geTMap(
                          latestContents?.techArticles &&
                            latestContents?.techArticles[2].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (latestContents?.techArticles &&
                              latestContents?.techArticles[3]?.techCategory?.id)
                          }
                        >
                          {latestContents?.techArticles &&
                            latestContents?.techArticles[3]?.techCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.techArticles &&
                            new Date(
                              latestContents?.techArticles[3]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.techArticles &&
                            latestContents?.techArticles[3]?.id)
                        }
                      >
                        {latestContents?.techArticles &&
                          latestContents?.techArticles[3]?.title}
                      </a>
                      <p className="tspl">
                        {geTMap(
                          latestContents?.techArticles &&
                            latestContents?.techArticles[3].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pt-3 pt-lg-0">
              <FollowUs />
              <NewsLetter />
              <div className="mb-3 pb-3">
                <script
                  type="text/javascript"
                  src="https://udbaa.com/bnr.php?section=General&pub=572899&format=300x250&ga=g"
                ></script>
                <noscript>
                  <a href="https://yllix.com/publishers/572899" target="_blank">
                    <img
                      src="//ylx-aff.advertica-cdn.com/pub/300x250.png"
                      className="ylixs"
                      alt="ylliX - Online Advertising Network"
                    />
                  </a>
                </noscript>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
