import React, { FC, useEffect, useState } from "react";
import "./TagFetcher.css";
import axios from "axios";
import { mainTags } from "../../interfaces";

interface TagFetcherProps {}

const TagFetcher: FC<TagFetcherProps> = () => {
  const [mainTags, setMainTags] = useState<mainTags[]>();
  const url = `https://apis.nuhman.com/`;

  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/main-tags`)
      .then((res) => {
        //setCountries(res?.data);
        setMainTags(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="TagFetcher">
      {mainTags?.map((article: mainTags, index) => (
        <a
          href={"/#/tags?name=" + article?.name}
          className="btn btn-sm btn-outline-secondary m-1"
          key={index}
        >
          {article.name}
        </a>
      ))}
    </div>
  );
};

export default TagFetcher;
