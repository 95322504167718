import React, { FC, useEffect, useState } from "react";
import "./Footer.css";
import { educationCategory, techCategory } from "../../interfaces";
import axios from "axios";
import TagFetcher from "../TagFetcher/TagFetcher";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  const [categories, setCategories] = useState<techCategory[]>();
  const url = `https://apis.nuhman.com/`;
  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/categories`)
      .then((res) => {
        setCategories(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <div className="container-fluid bg-light pt-5 px-sm-3 px-md-5">
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-5">
            <a href="index.html" className="navbar-brand">
              <h3 className="mb-2 mt-n2 display-5 text-uppercase">
                <span className="text-primary">NUHMAN</span>.COM
              </h3>
            </a>
            <p>
              Information Technology website for Programming & Development, Web
              Design & UX/UI, Startups & Innovation, Gadgets & Consumer Tech,
              Cloud Computing & Enterprise Tech, Cybersecurity, Artificial
              Intelligence (AI) & Machine Learning (ML), Gaming Technology,
              Mobile Development, Tech News & Trends, Open Source & Linux, Data
              Science & Analytics
            </p>
            <div className="d-flex justify-content-start mt-4">
              <a
                className="btn btn-outline-secondary text-center mr-2 px-0 links"
                href="#"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn btn-outline-secondary text-center mr-2 px-0 links"
                href="#"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-outline-secondary text-center mr-2 px-0 links"
                href="#"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="btn btn-outline-secondary text-center mr-2 px-0 links"
                href="#"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                className="btn btn-outline-secondary text-center mr-2 px-0 links"
                href="#"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <h4 className="font-weight-bold mb-4">Categories</h4>
            <div className="d-flex flex-wrap m-n1">
              {categories
                ?.slice(0, 10)
                .map((ctry: educationCategory, index) => (
                  <a
                    href=""
                    className="btn btn-sm btn-outline-secondary m-1"
                    key={index}
                  >
                    <a href={"/#/categories?catid=" + ctry.id}> {ctry.name}</a>{" "}
                  </a>
                ))}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <h4 className="font-weight-bold mb-4">Tags</h4>
            <div className="d-flex flex-wrap m-n1">
              <TagFetcher />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-5">
            <h4 className="font-weight-bold mb-4">Quick Links</h4>
            <div className="d-flex flex-column justify-content-start">
              <a className="text-secondary mb-2" href="/#/contact">
                <i className="fa fa-angle-right text-dark mr-2"></i>About
              </a>
              <a className="text-secondary mb-2" href="/#/contact">
                <i className="fa fa-angle-right text-dark mr-2"></i>Advertise
              </a>
              <a className="text-secondary mb-2" href="/#/privacy">
                <i className="fa fa-angle-right text-dark mr-2"></i>Privacy &
                policy
              </a>
              <a className="text-secondary mb-2" href="/#/terms">
                <i className="fa fa-angle-right text-dark mr-2"></i>Terms &
                conditions
              </a>
              <a className="text-secondary" href="/#/contact">
                <i className="fa fa-angle-right text-dark mr-2"></i>Contact
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-4 px-sm-3 px-md-5">
        <p className="m-0 text-center">
          &copy;{" "}
          <a className="font-weight-bold" href="#">
            Nuhman.com
          </a>
          . All Rights Reserved. Designed by{" "}
          <a className="font-weight-bold" href="https://htmlcodex.com">
            HTML Codex
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
