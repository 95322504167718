import React, { FC, useEffect, useState } from "react";
import "./JsonFormatter.css";
import { Helmet } from "react-helmet";
import JSONPretty from "react-json-pretty";
import axios from "axios";

interface JsonFormatterProps {}

const JsonFormatter: FC<JsonFormatterProps> = () => {
  const [message, setMessage] = useState<string>();
  const [result, setResult] = useState<string>();
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const url = `https://apis.nuhman.com/`;
  function submitContact(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setLoading(true);
    if (message === null) {
      setError(true);
      return;
    }
    setError(false);
    try {
      const prettyJson = JSON.stringify(JSON.parse(message || ""), null, 4);
      setResult(prettyJson);
      setLoading(false);
    } catch (e) {
      setResult("Invalid JSON");
      setLoading(false);
    }
  }

  useEffect(() => {
    const url = `https://apis.nuhman.com/`;
    axios
      .post(url + `apis/nuhman/tech/page-view?pageId=` + JsonFormatter.name)
      .then((res) => {
        //setCountries(res?.data);
        //setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - JSON Formatter"}</title>
        <meta name="description" content={"Copy your json to format"} />
        <meta property="og:title" content={"Nuhman.com - JSON Formatter"} />
        <meta
          property="og:description"
          content={"Paste your json text to beautify formatted"}
        />
      </Helmet>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult col-lg-12">
                  <h3 className=" mg">{"JSON Formatter"}</h3>
                  <p>Paste your json text to format/beautify</p>

                  {loading && (
                    <>
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                  )}

                  {!loading && (
                    <>
                      {" "}
                      <form
                        name="sentMessage"
                        id="contactForm"
                        onSubmit={submitContact}
                      >
                        <div className="control-group">
                          <textarea
                            className="form-control"
                            rows={4}
                            id="text"
                            name="text"
                            placeholder="Text"
                            onChange={(event) => setMessage(event.target.value)}
                            required
                            data-validation-required-message="Please enter your message"
                          ></textarea>
                          <p className="help-block text-danger"></p>
                        </div>
                        <div>
                          <button
                            className="btn btn-primary font-weight-semi-bold px-4"
                            style={{ height: 50 }}
                            type="submit"
                            id="sendMessageButton"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </>
                  )}

                  <div>
                    {result && (
                      <>
                        {" "}
                        <br />
                        <pre
                          style={{
                            backgroundColor: "#f4f4f4",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          {result}
                        </pre>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <p>**</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JsonFormatter;
