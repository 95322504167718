import React, { FC, useState } from "react";
import "./CompareTextAreas.css";
import { Helmet } from "react-helmet";

interface CompareTextAreasProps {}

const CompareTextAreas: FC<CompareTextAreasProps> = () => {
  const [textAreaOne, setTextAreaOne] = useState<string>("");
  const [textAreaTwo, setTextAreaTwo] = useState<string>("");
  const splitText = (text: string) => {
    // Split by spaces or newlines but capture the delimiter so it can be preserved
    return text.split(/(\s+|\n)/);
  };

  // Function to render text with highlighting
  const highlightText = (text: string, compareText: string) => {
    const textWords = splitText(text);
    const compareWords = new Set(
      splitText(compareText).filter((word) => word.trim())
    ); // Filtering empty strings

    return textWords.map((word, index) => {
      // Check if the word exists in the other text
      const isMatching = compareWords.has(word.trim()); // Trim to avoid matching empty spaces
      const isLineBreak = word === "\n"; // Check if it's a line break

      return isLineBreak ? (
        <br key={index} />
      ) : (
        <span
          key={index}
          style={{
            color: isMatching ? "green" : "red",
            fontWeight: isMatching ? "bold" : "normal",
          }}
        >
          {word}
        </span>
      );
    });
  };

  return (
    <>
      {" "}
      <Helmet>
        <title>{"Nuhman.com - Compare text Tool"}</title>
        <meta
          name="description"
          content={"Compare two text area content, find missing words"}
        />
        <meta property="og:title" content={"Nuhman.com - Compare Text Tool"} />
        <meta
          property="og:description"
          content={"Compare two text area content, find missing words"}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="overlay position-relative bg-light rresult">
              <div className="mb-3 rresult col-lg-12">
                <div>
                  <h3>Compare Two Text Areas with Highlights</h3>
                  <textarea
                    value={textAreaOne}
                    onChange={(e) => setTextAreaOne(e.target.value)}
                    placeholder="Text Area 1"
                    rows={6}
                    cols={50}
                  />
                  <br />
                  <textarea
                    value={textAreaTwo}
                    onChange={(e) => setTextAreaTwo(e.target.value)}
                    placeholder="Text Area 2"
                    rows={6}
                    cols={50}
                  />
                  <br />

                  <h4>Text Area 1 Highlighted</h4>
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                      border: "1px solid black",
                      padding: "10px",
                    }}
                  >
                    {highlightText(textAreaOne, textAreaTwo)}
                  </div>

                  <h4>Text Area 2 Highlighted</h4>
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                      border: "1px solid black",
                      padding: "10px",
                    }}
                  >
                    {highlightText(textAreaTwo, textAreaOne)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p>**</p>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default CompareTextAreas;
